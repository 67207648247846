import React, { Component } from "react"
import { Link } from "gatsby"
import logoBigImperia from "../../images/logo-big-imperia.png"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ym from "react-yandex-metrika"
import "./mobile-index-page-grid.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { faTelegram, faVk } from "@fortawesome/free-brands-svg-icons"
// Fix huge icon flash: https://github.com/FortAwesome/react-fontawesome/issues/234
config.autoAddCss = false
export default class MobileGrid extends Component {
  constructor(props) {
    super(props)
  }

  handleMailClick() {
    ym("reachGoal", "mail1")
  }
  handlePhoneOneClick() {
    ym("reachGoal", "phone1")
  }
  handlePhoneTwoClick() {
    ym("reachGoal", "phone2")
  }
  /* handleInstagramClick() {
    ym("reachGoal", "instagram1")
  } */
  handleVkontakteClick() {
    ym("reachGoal", "vkontakte")
  }

  render() {
    return (
      <>
        <Container fluid className="imperia-mobile-container" style={{ display: 'none' }}>
          <div className="text-center">
            <img
              src={logoBigImperia}
              className="img-fluid logo-big-imperia mx-auto my-0 py-3"
            ></img>
          </div>
          <Row>
            <Col md={6} className="mt-3">
              <ul className="list-group list-group-flush ml-0">
                <Link
                  to="/contacts"
                  className="text-white font-weight-bold text-uppercase"
                >
                  <li className="list-group-item bg-transparent mb-0 text-center">
                    Контакты
                  </li>
                </Link>
                <Link
                  to="/catalog"
                  className="text-white font-weight-bold text-uppercase"
                >
                  <li className="list-group-item bg-transparent mb-0 text-center border-top-0">
                    Каталог
                  </li>
                </Link>
                <Link
                  to="/about-us"
                  className="text-white font-weight-bold text-uppercase"
                >
                  <li className="list-group-item bg-transparent mb-0 text-center border-top-0">
                    О нас
                  </li>
                </Link>
                <Link
                  to="/promotions"
                  className="text-white font-weight-bold text-uppercase"
                >
                  <li className="list-group-item bg-transparent mb-0 text-center border-top-0">
                    Акции
                  </li>
                </Link>
                <Link
                  to="/sale"
                  className="text-white font-weight-bold text-uppercase"
                >
                  <li className="list-group-item bg-transparent mb-0 text-center border-top-0">
                    Распродажа
                  </li>
                </Link>
              </ul>
            </Col>
            <Col md={6} className="mt-3">
              <ul className="list-group list-group-flush ml-0">
                <a href="mailto:9080881@mail.ru" onClick={this.handleMailClick}>
                  <li className="list-group-item bg-transparent mb-0 text-center">
                    <FontAwesomeIcon className="text-white" icon={faEnvelope} />
                  </li>
                </a>
                <a
                  href="tel:+7-999-005-1925"
                  onClick={this.handlePhoneOneClick}
                  className="text-white"
                >
                  <li className="list-group-item bg-transparent mb-0 text-center border-top-0">
                    <FontAwesomeIcon
                      className="text-white mr-1"
                      icon={faPhone}
                    />
                    (МЦ «Мебельный Континент»)
                  </li>
                </a>
                <a
                  href="tel:+7-921-908-0881"
                  onClick={this.handlePhoneTwoClick}
                  className="text-white"
                >
                  <li className="list-group-item bg-transparent mb-0 text-center border-top-0">
                    <FontAwesomeIcon
                      className="text-white mr-1"
                      icon={faPhone}
                    />
                    (МЦ «Мебель Холл»)
                  </li>
                </a>
                <a
                  href="https://vk.com/club95884987"
                  onClick={this.handleVkontakteClick}
                >
                  <li className="list-group-item bg-transparent mb-0 text-center border-top-0">
                    <FontAwesomeIcon className="text-white" icon={faVk} />
                  </li>
                </a>
                <a
                  href="https://t.me/IMPERIA_St_meb"
                  /* onClick={this.handleInstagramClick} */
                >
                  <li className="list-group-item bg-transparent mb-0 text-center border-top-0">
                    <FontAwesomeIcon
                      className="text-white"
                      icon={faTelegram}
                    />
                  </li>
                </a>
              </ul>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
