import React from "react"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { window } from "browser-monads"
import BackgroundSlider from "gatsby-image-background-slider"
import MobileGrid from "../components/mobile-index-page-grid"
import CookiesAlert from "../components/cookies-info-alert"
import DesktopGrid from "../components/desktop-index-page-grid"
import { Helmet } from "react-helmet"

import { YMInitializer } from "react-yandex-metrika"

export default function IndexPage() {
  return (
    <>
      <SEO title="Главная страница" />
      <Helmet>
        <meta
          name="description"
          content="Сайт компании Империя студия мебели. Мы производим и проектируем различные виды мебели, кухни, шкафы, шкафы-купе, тумбы, прихожие и гардеробные - на заказ и по индивидуальным дизайнерским проектам."
        />
        <meta
          name="keywords"
          content="Империя мебель, Империя шкафы, Империя шкафы-купе, Шкафы-купе спб, Империя кухни, Мебель на заказ, Производство мебели, Мебель спб, Шкафы-купе спб, Кухни спб"
        />
        <meta name="yandex-verification" content="612620d454399fee" />
      </Helmet>
      <YMInitializer accounts={[61638022]} options={{ defer: true }} />
      <BackgroundSlider
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { sourceInstanceName: { eq: "backgrounds" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 85) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `)}
        initDelay={2} // delay before the first transition (if left at 0, the first image will be skipped initially)
        transition={4} // transition duration between images
        duration={6} // how long an image is shown
        // specify images to include (and their order) according to `relativePath`
        images={["1.jpg", "2.jpg", "3.jpg"]}
        style={{
          width: "100%",
          height: "100%",
        }}
      ></BackgroundSlider>
      <CookiesAlert />
      <MobileGrid />

      <DesktopGrid />
    </>
  )
}
