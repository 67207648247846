import React, { Component } from "react"
import { Link } from "gatsby"
import logoBigImperia from "../../images/logo-big-imperia.png"
import ym from "react-yandex-metrika"
import "./desktop-index-page-grid.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { faTelegram, faVk } from "@fortawesome/free-brands-svg-icons"
import Tooltip from "@material-ui/core/Tooltip"
import Fade from 'react-reveal/Fade'
// Fix huge icon flash: https://github.com/FortAwesome/react-fontawesome/issues/234
config.autoAddCss = false
export default class DesktopGrid extends Component {
  constructor(props) {
    super(props)
  }

  handleMailClick() {
    ym("reachGoal", "mail1")
  }
  handlePhoneOneClick() {
    ym("reachGoal", "phone1")
  }
  handlePhoneTwoClick() {
    ym("reachGoal", "phone2")
  }
  /* handleInstagramClick() {
    ym("reachGoal", "instagram1")
  } */
  handleVkontakteClick() {
    ym("reachGoal", "vkontakte")
  }

  render() {
    return (
      <>
        <div className="grid">
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <a
            className="m-0"
            href="mailto:9080881@mail.ru"
            onClick={this.handleMailClick}
          >
            <div className="grid-item d-flex align-items-center hover-imperia-item rgba-black-strong-imperia">
              <Tooltip title="Отправить E-mail" placement="bottom">
                <span className="mx-auto">
                  <FontAwesomeIcon
                    className="text-white fa-lg"
                    icon={faEnvelope}
                  />
                </span>
              </Tooltip>
            </div>
          </a>
          <div className="grid-item"></div>
          <div className="span-col-3 grid-item d-flex align-items-center rgba-black-strong-imperia logo-imperia hover-imperia-item">
            <div className="mx-auto">
              <img
                src={logoBigImperia}
                className="img-fluid logo-big-imperia mx-auto my-0"
              ></img>
            </div>
          </div>
          <div className="grid-item"></div>
          <Fade duration={1000} delay={150}>
            <div className="grid-item d-flex align-items-center rgba-black-strong-imperia text-uppercase h3-responsive text-white contacts-imperia element-pulsing-bg hover-imperia-item imperia-nav-link-effect">

              <Link
                to="/contacts"
                className="text-white mx-auto my-0 font-weight-bold h5"
              >
                Контакты
              {/* <span data-hover="Контакты">Контакты</span> */}
              </Link>

            </div>
          </Fade>
          <a
            className="m-0"
            href="tel:+7-999-005-1925"
            onClick={this.handlePhoneOneClick}
          >
            <div className="grid-item d-flex align-items-center hover-imperia-item text-center rgba-black-strong-imperia">
              {/*   <Tooltip
                title="+7 (999) 005-19-25 (МЦ «Мебельный Континент»)"
                placement="bottom"
              > */}
              <span className="mx-auto">
                <FontAwesomeIcon
                  className="text-white fa-lg"
                  icon={faPhone}
                />
                <br />
                <p className="mb-0 text-white">+7-999-005-1925 <br /><small>МЦ «Мебельный Континент»</small></p>

              </span>
              {/* </Tooltip> */}
            </div>
          </a>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <Fade duration={1000} delay={300}>
            <div className="grid-item d-flex align-items-center rgba-black-strong-imperia text-uppercase h3-responsive text-white catalog-imperia hover-imperia-item imperia-nav-link-effect">

              <Link
                to="/about-us"
                className="text-white mx-auto my-0 font-weight-bold h5"
              >
                О нас
              {/* <span data-hover="О нас">О нас</span> */}
              </Link>

            </div>
          </Fade>
          <div className="grid-item"></div>
          <a
            className="m-0"
            href="tel:+7-921-908-0881"
            onClick={this.handlePhoneTwoClick}
          >
            <div className="grid-item d-flex align-items-center hover-imperia-item text-center rgba-black-strong-imperia">
              {/*  <Tooltip
                title="+7 (921) 908-08-81 (МЦ «Мебель Холл»)"
                placement="bottom"
              > */}
              <span className="mx-auto">
                <FontAwesomeIcon
                  className="text-white fa-lg"
                  icon={faPhone}
                />
                <br />
                <p className="mb-0 text-white">+7-921-908-0881 <br /><small>МЦ «Мебель Холл»</small></p>
              </span>
              {/* </Tooltip> */}
            </div>
          </a>
          <div className="grid-item"></div>
          <Fade duration={1000} delay={600}>
            <div className="span-col-2 grid-item d-flex align-items-center rgba-black-strong-imperia text-uppercase h3-responsive text-white works-imperia hover-imperia-item imperia-nav-link-effect">

              <Link
                to="/catalog"
                className="text-white mx-auto my-0 font-weight-bold h5"
              >
                Каталог
              {/* <span data-hover="Каталог">Каталог</span> */}
              </Link>

            </div>
          </Fade>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <Fade duration={1000} delay={450}>
            <div className="grid-item d-flex align-items-center rgba-black-strong-imperia text-uppercase h3-responsive text-white about-imperia hover-imperia-item imperia-nav-link-effect">

              <Link
                to="/promotions"
                className="text-white mx-auto my-0 font-weight-bold h5"
              >
                Акции
              {/* <span data-hover="Акции">Акции</span> */}
              </Link>

            </div>
          </Fade>
          <a
            className="m-0"
            href="https://vk.com/club95884987"
            onClick={this.handleVkontakteClick}
          >
            <div className="grid-item d-flex align-items-center hover-imperia-item rgba-black-strong-imperia">
              <span className="mx-auto">
                <FontAwesomeIcon className="text-white fa-lg" icon={faVk} />
              </span>
            </div>
          </a>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <Fade duration={1000} delay={750}>
            <div className="grid-item d-flex align-items-center rgba-black-strong-imperia text-uppercase h3-responsive text-white about-imperia hover-imperia-item imperia-nav-link-effect">

              <Link
                to="/sale"
                className="text-white mx-auto my-0 font-weight-bold h5"
              >
                Распродажа
              
              </Link>

            </div>
          </Fade>
          <div className="grid-item"></div>
          <a
            className="m-0"
            href="https://t.me/IMPERIA_St_meb"
            /* onClick={this.handleInstagramClick} */
          >
            <div className="grid-item d-flex align-items-center hover-imperia-item rgba-black-strong-imperia">
              <span className="mx-auto">
                <FontAwesomeIcon
                  className="text-white fa-lg"
                  icon={faTelegram}
                />
              </span>
            </div>
          </a>
        </div>
      </>
    )
  }
}
